import React from 'react';
import { above, below } from 'src/components/global/mediaqueries';
import BigPostCard from 'src/components/post/BigPostCard';
import formatDate from 'src/utils/formatDate';
import Masonry from 'react-masonry-css';
import ModuleHeader from 'src/components/ModuleHeader';
import PostCard from 'src/components/post/PostCard';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const PostsWrapper = styled('div')`
    background-color: ${theme.color.white};
    color: ${theme.color.black};

    ${above.tablet_s} {
        display: flex;
        flex-direction: row;
    }
`;

const MainPost = styled('div')`
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._40('padding-bottom')};

    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.tablet._10('padding-right')};
    ${theme.spacing.tablet._10('padding-bottom')};

    ${theme.spacing.desktop._32('padding-left')};
    ${theme.spacing.desktop._16('padding-right')};
    ${theme.spacing.desktop._64('padding-bottom')};

    ${above.tablet_s} {
        width: 50%;
        flex: 0 0 50%;
    }
`;

const PostListing = styled('div')`
    overflow: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._40('padding-bottom')};

    ${theme.spacing.tablet._10('padding-left')};
    ${theme.spacing.tablet._20('padding-right')};
    ${theme.spacing.tablet._10('padding-bottom')};

    ${theme.spacing.desktop._16('padding-left')};
    ${theme.spacing.desktop._32('padding-right')};
    ${theme.spacing.desktop._64('padding-bottom')};

    ${above.tablet_s} {
        width: 50%;
        flex: 0 0 50%;

        display: flex;
        flex-flow: column wrap;
        align-content: space-between;
    }

    .grid {
        display: flex;
        width: auto;


        ${below.tablet_s} {
            width: 500vw;
            flex: 0 0 500vw;
            ${theme.spacing.mobile._12('padding-right')};
        }

        &__column {
            background-clip: padding-box;
            display: block;
            ${theme.spacing.mobile._16('margin-left')};
            ${theme.spacing.tablet._20('margin-left')};

            &:first-of-type {
                margin-left: 0;
            }
        }


    }
`;

const Post = styled(PostCard)`
    width: 100%;
    ${theme.spacing.tablet._32('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};
`;

const BigPost = styled(BigPostCard)`
    ${theme.spacing.tablet._32('margin-bottom')};
`;

const BigPostWrapper = styled('div')`
    ${above.tablet_s} {
        position: sticky;
        top: 0;
    }
`;

const PostsModule = (props) => {
    const {data} = props;
    const {heading, highlightedPosts, link, latestPosts} = data;

    const postList = highlightedPosts;
    const highlightedPostsIDs = [];
    const postListExists = postList !== false;

    postListExists && postList.map((postData) => {
        postData.post.ID && highlightedPostsIDs.push(postData.post.ID);
    });

    postListExists && latestPosts.map((postData) => {
        !highlightedPostsIDs.includes(postData.post.ID) &&
        postList.push(postData);
    });

    const shouldRender = postListExists && postList.indexOf(false) < 0 && postList.length >= 3;

    const bigPost = postListExists && postList[0].post;

    return (
        shouldRender && (
            <div>
                <ModuleHeader heading={heading} link={link}/>
                <PostsWrapper>
                    <MainPost>
                        {bigPost &&
                            <BigPostWrapper>
                                <BigPost
                                    date={formatDate(bigPost.postDate) || ''}
                                    image={bigPost.acfData.postImage || ''}
                                    tag={bigPost.mainCategory.name || ''}
                                    text={bigPost.postTitle || ''}
                                    url={bigPost.acfData.permalink || ''}
                                    />
                            </BigPostWrapper>
                        }
                    </MainPost>
                    <PostListing>
                        <Masonry
                            breakpointCols={{default: 2, 720: 6}}
                            className="grid"
                            columnClassName="grid__column"
                        >
                            {postList.slice(1, 7).map((postData) => {
                                const post = postData.post;
                                const category = post.mainCategory.name || '';
                                const date = formatDate(post.postDate);
                                const image = post.acfData.postImage || '';
                                const title = post.postTitle || '';
                                const url = post.acfData.permalink || '';
                                return(
                                    <Post
                                        date={date}
                                        image={image}
                                        imageSize="small"
                                        key={post.ID}
                                        tag={category}
                                        text={title}
                                        url={url}
                                    />
                                );
                            })}
                        </Masonry>
                    </PostListing>
                </PostsWrapper>
            </div>
        )
    );
};

PostsModule.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        highlightedPosts: PropTypes.array,
        latestPosts: PropTypes.array,
        link: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string
        })
    })
};

PostsModule.defaultProps = {
    data: {
        heading: '',
        highlightedPosts: [],
        latestPosts: [],
        link: {},
    }
};

export default PostsModule;
